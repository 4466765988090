import ComingSoon from '@components/ComingSoon'
import Error from '@components/Error'
import ErrorPlaceholder from '@components/ErrorPlaceholder'
import Maintenance from '@components/Maintenance'
import AccessDisabledModal from '@components/Modal/AccessDisabledModal'
import MaintenanceModal from '@components/Modal/Maintenance'
import PageOverlay from '@components/PageOverlay'
import withCurrentPermission, { PermissionsProps } from '@hocs/WithCurrentPermission'
import CustomPageMeta from '@hocs/WithPortalAuthorization/CustomMeta'
import { SessionProps, StoreProps } from '@interfaces/StoreState'
import { UserProfileProps } from '@interfaces/UserProfile'
import { PAGE } from '@utils/constants'
import { PROFILE_TYPE } from '@utils/constants'
import { formQueryString, getQueryParams } from '@utils/helpers'
import Router from 'next/router'
import React from 'react'
import { connect } from 'react-redux'

interface WithPortalAuthorizationProps {
  id: string
  queryParams?: string[]
}

export interface WithPortalAuthorizationHOCProps extends StoreProps, SessionProps, PermissionsProps {
  loading?: boolean
  profile?: UserProfileProps
  isAdmin?: boolean
  roles: string[]
}

export default function WithPortalAuthorization(Component: any, params: WithPortalAuthorizationProps) {
  class WithPortalAuthorizationHOC extends React.Component<WithPortalAuthorizationHOCProps> {
    constructor(props) {
      super(props)
      this.checkPermissions = this.checkPermissions.bind(this)
      this.getPermittedComponent = this.getPermittedComponent.bind(this)
      console.log('[WithPortalAuth] Initialized with params:', params)
    }

    checkPermissions() {
      console.log('[WithPortalAuth] Checking permissions:', this.props.currentPermission)
      return {
        ...this.props.currentPermission,
        shouldDispay: this.props.validPermission,
      }
    }

    getPermittedComponent() {
      const { profile, isAdmin, roles, showSiteMaintenance } = this.props
      console.log('[WithPortalAuth] Getting permitted component with:', {
        profileExists: !!profile?.SFId,
        isAdmin,
        rolesExist: !!roles?.length,
        roles: roles,
        currentPath: Router.router?.asPath,
      })

      if (showSiteMaintenance) {
        console.log('[WithPortalAuth] Showing site maintenance')
        return <MaintenanceModal open={showSiteMaintenance} />
      }

      if ((!profile?.SFId && !isAdmin) || !roles || !roles.length) {
        console.log('[WithPortalAuth] User not recognized')
        return <ErrorPlaceholder msg="User not recognized" />
      }

      const contractExpired =
        profile?.Type === PROFILE_TYPE.INSTRUCTOR &&
        (profile?.InstructorProfile?.instructorInfo?.ContractExpired ||
          profile?.InstructorProfile?.instructorInfo?.Status === 'Inactive')

      const studentProfileInactive =
        profile?.Type === PROFILE_TYPE.STUDENT && profile?.StudentProfile?.StudentProfileInfo?.Status === 'Inactive'

      if (studentProfileInactive) {
        console.log('[WithPortalAuth] Student profile inactive')
        const message = {
          header: 'Access disabled.',
          p1: 'Your student profile has been set as inactive.',
        }

        return <AccessDisabledModal open={studentProfileInactive} content={message} />
      }

      if (contractExpired) {
        console.log('[WithPortalAuth] Contract expired')
        return <AccessDisabledModal open={contractExpired} />
      }

      const pagePermission = this.checkPermissions()
      console.log('[WithPortalAuth] Page permission result:', pagePermission)

      if (pagePermission.shouldDispay) {
        console.log('[WithPortalAuth] Should display component')
        return <Component profile={profile} />
      }

      if (pagePermission.type !== PAGE.TYPES.PAGE || pagePermission.action === PAGE.ACTIONS.FORBIDDEN) {
        console.log('[WithPortalAuth] Forbidden access, returning 403')
        return <Error statusCode={403} />
      }

      if (pagePermission.action === PAGE.ACTIONS.MAINTENANCE) {
        console.log('[WithPortalAuth] Maintenance mode')
        return <Maintenance />
      }

      if (pagePermission.action === PAGE.ACTIONS.COMINGSOON) {
        console.log('[WithPortalAuth] Coming soon')
        return <ComingSoon />
      }

      if (pagePermission.action === PAGE.ACTIONS.SITEMAINTENANCE) {
        console.log('[WithPortalAuth] Site maintenance')
        return <MaintenanceModal />
      }

      if (
        pagePermission.action === PAGE.ACTIONS.REDIRECTION &&
        pagePermission.redirections &&
        pagePermission.redirections.length
      ) {
        console.log('[WithPortalAuth] Redirection action with redirections:', pagePermission.redirections)
        const redirections: string[] = []
        PAGE.ROLE_HIERARCHY.forEach((role) => {
          if (roles.includes(role)) {
            // @ts-ignore
            const redirect = pagePermission.redirections.find((redirection) => redirection.id === role)
            console.log('[WithPortalAuth] Checking redirect for role:', role, redirect)
            redirect && redirections.push(redirect.path)
          }
        })
        console.log('[WithPortalAuth] Collected redirections:', redirections)

        let redirectionBasedOnHierarchy = redirections[0]
        console.log('[WithPortalAuth] Selected redirection:', redirectionBasedOnHierarchy)

        if (params.queryParams && Array.isArray(params.queryParams)) {
          const approvedParams = {}
          params.queryParams.map((query) => {
            const queryValue = getQueryParams(query, Router.router?.asPath)
            if (queryValue) {
              approvedParams[query] = queryValue
            }
          })
          console.log('[WithPortalAuth] Approved query params:', approvedParams)

          const queries = formQueryString(approvedParams)
          redirectionBasedOnHierarchy = queries
            ? `${redirectionBasedOnHierarchy}?${queries}`
            : redirectionBasedOnHierarchy
          console.log('[WithPortalAuth] Final redirection with query params:', redirectionBasedOnHierarchy)
        }

        if (redirectionBasedOnHierarchy) {
          console.log('[WithPortalAuth] Redirecting to:', redirectionBasedOnHierarchy)
          Router.push(redirectionBasedOnHierarchy)
          return <PageOverlay />
        }
        console.log('[WithPortalAuth] No redirection path found, returning 404')
        return <Error statusCode={404} />
      }

      console.log('[WithPortalAuth] No matching condition, returning 404')
      return <Error statusCode={404} />
    }

    render() {
      return <CustomPageMeta configId={params.id}>{this.getPermittedComponent()}</CustomPageMeta>
    }
  }

  const mapStateToProps = ({ session, userProfile }: StoreProps) => ({
    roles: session.cognitoGroups,
    user: session.user,
    loading: session.loading,
    isAdmin: session.isAdmin,
    profile: userProfile.info,
  })

  // @ts-ignore
  return withCurrentPermission(connect(mapStateToProps)(WithPortalAuthorizationHOC), {
    id: params.id,
    includeSiteMaintenance: true,
  })
}
